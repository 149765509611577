<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark gradient-primary py-0" :key="$store.state.wallet.address">
    <div class="container-fluid">
      <router-link class="navbar-brand" to="/">
        <img src="/favicon.jpg" height="40" class="d-inline-block align-top me-2" style="border-radius: 4px" alt="">
        <span class="logo-name" style="line-height: 40px">RugCheck</span>
      </router-link>
      <div>
        <a title="Quick search" href="#search" class="me-3 d-inline-block d-lg-none"><i class="fa fa-search"></i></a>
        <button id="toggle" class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/">🔍 HOME</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/tokens">📊 TOKENS</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/verify/token">🛡️VERIFY <sup
                class="badge bg-primary px-1 py-0 d-lg-none d-xl-inline"><small>New</small></sup></router-link>
          </li>
          <!--					<li class="nav-item">-->
          <!--						<router-link class="nav-link" to="/leaderboard">LEADERBOARD</router-link>-->
          <!--					</li>-->
        </ul>

        <ul class="navbar-nav mx-auto mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/integrations">🧰 INTEGRATIONS <sup
                class="badge bg-primary px-1 py-0 d-lg-none d-xl-inline"><small>New</small></sup></router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://fluxbeam.xyz" target="_blank">💱 DEX</a>
          </li>
        </ul>

        <ul class="navbar-nav ms-auto mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" to="/settings"><i class="fa fa-cog"></i></router-link>
          </li>
          <li class="nav-item" v-if="$route.path.indexOf('auth') === -1">
            <button v-if="!connected" @click="login" class="btn btn-primary mt-2 login btn-sm py-0">Login</button>
            <button class="btn btn-primary mt-2 login btn-sm py-0" @click="logout" v-else>Logout</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  computed: {
    connected: function () {
      return this.$store.state.wallet.jwtToken
    },
  },
  methods: {
    logout: function (e) {
      if (e)
        e.preventDefault()

      console.log("Logging out")
      this.$toastr.e("Wallet disconnected")
      this.$store.commit("wallet/clear_wallet")
      localStorage.removeItem("wallet")
      localStorage.removeItem("walletName")
    },
    login: function(e) {
      if (e)
        e.preventDefault()
      this.$router.push(`/auth?redirectTo=${this.$route.path}`)
    }
  }
}
</script>